import { formServiceUrl } from "../util/apiUrlUtil";
import constants from "../util/constants/constants.json";
import apiClient from "../util/apiClient";
import IPriceData from "../models/priceView/IPriceData";
import PriceDataModel from "../models/priceView/PriceData.model";
import IUpdateStatus from "../models/priceView/IUpdateStatus";
import { getUserName } from "../util/util";

export const getProposedPrices = async (seiId: string) => {
  try {
    const serviceUrl = await formServiceUrl(
      constants.urlConstants.priceGenPriceViewMaster.name,
      constants.urlConstants.priceGenPriceViewMaster.fetchLatestPrices
    );

    const response = await apiClient.post<IPriceData[]>(serviceUrl, { seiId });
    console.log("RAW DATA DATA DATA");
    console.log(response.data);
    return response.data
      .sort((a, b) => {
        const pidA = parseInt(a.productId, 10);
        const pidB = parseInt(b.productId, 10);
        if (pidA < pidB) {
          return -1;
        }
        if (pidA > pidB) {
          return 1;
        }
        return 0;
      })
      .map((priceData) => new PriceDataModel(priceData));
  } catch (error) {
    if (error.response) {
      throw new Error(`HTTP error! status: ${error.response.status}`);
    } else if (error.request) {
      throw new Error(`HTTP error! No response received.`);
    } else {
      throw new Error(`Error: ${error.message}`);
    }
  }
};

export const callForceGeneratePrice = async (fpisId: string, seiId: string) => {
  try {
    const serviceUrl = await formServiceUrl(
      constants.urlConstants.priceGenPriceViewMaster.name,
      constants.urlConstants.priceGenPriceViewMaster.triggerForceGeneration
    );
    const response = await apiClient.post(serviceUrl, {
      fpisId: fpisId,
      seiId: seiId,
      userId: getUserName(),
    });
    // console.debug(response);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`HTTP error! status: ${error.response.status}`);
    } else if (error.request) {
      throw new Error(`HTTP error! No response received.`);
    } else {
      throw new Error(`Error: ${error.message}`);
    }
  }
};

export const updateStatusForStoreProduct = async (
  updateStatus: IUpdateStatus[]
) => {
  try {
    const serviceUrl = await formServiceUrl(
      constants.urlConstants.priceGenPriceViewMaster.name,
      constants.urlConstants.priceGenPriceViewMaster.updateStatusForStoreProduct
    );
    console.debug("updateStatusForStoreProduct, serviceUrl: ", serviceUrl);
    const response = await apiClient.post(serviceUrl, updateStatus);
    console.debug("updateStatusForStoreProduct, response: ", response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw new Error(`HTTP error! status: ${error.response.status}`);
    } else if (error.request) {
      throw new Error(`HTTP error! No response received.`);
    } else {
      throw new Error(`Error: ${error.message}`);
    }
  }
};
