import { formServiceUrl } from "../util/apiUrlUtil";
import constants from "../util/constants/constants.json";
import apiClient from "../util/apiClient";
import { IAllHistory } from "../models/history/IProductHistory";

export const getHistoryData = async (
    seiId: string,
    productIds: string[],
    competitorImportCodes: string[],
    fromDate: Date,
    toDate: Date,
    setIsLoading: (isLoading: boolean) => void
): Promise<IAllHistory> => {
  try {
    setIsLoading(true);
    const serviceUrl = await formServiceUrl(
        constants.urlConstants.priceGenPriceViewMaster.name,
        constants.urlConstants.priceGenPriceViewMaster.fetchAllCategoryHistory
    );

    if (!toDate) {
      toDate = new Date();
    }

    if (!fromDate){
      fromDate = new Date(new Date().setDate(new Date().getDate() - 30));
    }

    if (!fromDate && !toDate) {
      toDate = new Date()
      fromDate = new Date(new Date().setDate(new Date().getDate() - 30));
    }

    let toDateString: string = null;
    let fromDateString: string = null;
    if (toDate) toDateString = formatDate(toDate);
    if (fromDate) fromDateString = formatDate(fromDate);
    const payload = {
      seiId: seiId,
      productIdList: productIds,
      competitorImportCodes: competitorImportCodes,
      statuses: ["APPROVED", "REJECTED", "EXPORTED"],
      fromDate: fromDateString ? formatDate(fromDate) : null,
      toDate: toDateString ? formatDate(toDate) : null,
    };

    const response = await apiClient.post<IAllHistory>(serviceUrl, payload);
    console.debug(
        `Successfully fetched history data: ${JSON.stringify(response.data)}`
    );
    setIsLoading(false);
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error(
          `HTTP error while getting history data: ${error.response.data}`
      );
    } else if (error.request) {
      console.error(`HTTP error while getting history data: ${error.request}`);
    } else {
      console.error(
          `Unknown error while getting history data: ${error.message}`
      );
    }
    setIsLoading(false);
    return {
      storeId: seiId,
      histories: {},
    };
  }
};

function formatDate(date) {
  const pad = (num) => num.toString().padStart(2, "0");

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
